import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import analyticReducer from './slices/analytic';
import productReducer from './slices/product';
import productDetailReducer from './slices/product-detail';
import tariffReducer from './slices/tariffs';
import commonReducer from './slices/common';
import forumReducer from './slices/forum';
import featuredCategoriesReducer from './slices/featured-categories';
import categoryAnalyticsReducer from './slices/category-analytics';
import categoryTreeReducer from './slices/category-tree';
import autoDumpingReducer from './slices/auto-dumping';
import marketplaceReducer from './slices/marketplace';
import descriptionGeneratorKeyWordsReducer from './slices/semanticCore';
import authReducer from './slices/authentication';
import portalSpecialists from './slices/portalSpecialists';
import videoTeachingReducer from './slices/video-teaching';
import catalogReducer from './slices/catalog';
import cartReducer from './slices/cart';
import categoriesSlice from './slices/catogories';
import catalogProductSlice from './slices/catalog_product';
import catalogBrandsSlice from './slices/catalog_brands';
import catalogFavoritesSlice from './slices/catalogFavorites';
import catalogOrdersSlice from './slices/catalog_orders';
import catalogMyProductsSlice from './slices/catalog_my_products';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['marketplace'],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const videoTeachingPersistConfig = {
  key: 'video_teaching',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['filter'],
};

const productDetailPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['timeRange'],
};

const rootReducer = combineReducers({
  user: userReducer,
  analytic: analyticReducer,
  product: persistReducer(productPersistConfig, productReducer),
  product_detail: persistReducer(
    productDetailPersistConfig,
    productDetailReducer,
  ),
  plan: tariffReducer,
  common: commonReducer,
  forum: forumReducer,
  featured: featuredCategoriesReducer,
  categoryAnalytics: categoryAnalyticsReducer,
  category: categoryTreeReducer,
  dumping: autoDumpingReducer,
  marketplace: marketplaceReducer,
  descriptionGeneratorKeyWords: descriptionGeneratorKeyWordsReducer,
  auth: authReducer,
  portalSpecialists: portalSpecialists,
  video_teaching: persistReducer(
    videoTeachingPersistConfig,
    videoTeachingReducer,
  ),
  catalog: catalogReducer,
  cart: cartReducer,
  categories: categoriesSlice,
  catalog_product: catalogProductSlice,
  catalog_brands: catalogBrandsSlice,
  catalog_favorites: catalogFavoritesSlice,
  catalog_orders: catalogOrdersSlice,
  catalog_my_products: catalogMyProductsSlice,
});

export { rootPersistConfig, rootReducer };
