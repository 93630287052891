import React from 'react';
import { Typography } from '@mui/material';

const variantStyles = {
  title1: {
    fontSize: '18px',
    fontWeight: 500,
  },
  body: {
    fontSize: '12px',
    fontWeight: 400,
  },
  body1: {
    fontSize: '12px',
    fontWeight: 500,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 500,
  },
  body3: {
    fontSize: '14px',
    fontWeight: 400,
  },
  body4: {
    fontSize: '14px',
    fontWeight: 700,
  },
  h1: {
    fontSize: '96px',
    fontWeight: 300,
  },
  h2: {
    fontSize: '60px',
    fontWeight: 300,
  },
  h3: {
    fontSize: '48px',
    fontWeight: 400,
  },
  h4: {
    fontSize: '34px',
    fontWeight: 400,
  },
  h5: {
    fontSize: '24px',
    fontWeight: 400,
  },
  h6: {
    fontSize: '20px',
    fontWeight: 500,
  },
};

const CustomTypography = ({ variant, children, ...props }) => {
  const style = variantStyles[variant] || {};

  return (
    <Typography sx={style} {...props}>
      {children}
    </Typography>
  );
};

export default CustomTypography;
